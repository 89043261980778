import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

const withContainer = Component => ({ mode }) => {
  const billing_status_list = hooks.useBillingStatusList()
  const order_status_list = hooks.useOrderStatusList()
  const transport_status_list = hooks.useTransportStatusList()
  const [company, contract] = hooks.useFieldValues(['company', 'contract'])

  const orderStatusOptions = _.map(order_status_list, (status) => ({ label: I18n.t(`order.status.${status}`), value: status }))
  const transportStatusOptions = _.map(transport_status_list, (status) => ({ label: I18n.t(`transport.status.${status}`), value: status }))
  const billingStatusOptions = _.map(billing_status_list, (status) => ({ label: I18n.t(`billing.status.${status}`), value: status }))

  const autocompleteMutatorDo = useCallback((q) => ({
    or: [
      { customerinfo: { client_number: { startsWith: _.split(q, ' ') } } },
      { customerinfo: { name: { startsWith: _.split(q, ' ') } } },
      { info: { first_name: { startsWith: _.split(q, ' ') } } },
      { info: { last_name: { startsWith: _.split(q, ' ') } } }
    ]
  }), [])
  const autocompleteMutatorB2B = useCallback(q => ({
    or: [{ client_id: { startsWith: q } }, { name: { startsWith: q } }]
  }), [])
  const autocompleteMutatorDriver = useCallback(q => ({
    or: [
      { info: { user_client_id: { startsWith: _.split(q, ' ') } } },
      { info: { first_name: { startsWith: _.split(q, ' ') } } },
      { info: { last_name: { startsWith: _.split(q, ' ') } } }
    ]
  }), [])
  const autocompleteMutatorOperator = useCallback(q => ({
    auth: { username: { startsWith: q } }
  }), [])

  const mutators = {
    do: autocompleteMutatorDo,
    b2b: autocompleteMutatorB2B,
    operator: autocompleteMutatorOperator,
    driver: autocompleteMutatorDriver
  }

  const mergedProps = {
    company,
    contract,
    mode,
    billingStatusOptions,
    orderStatusOptions,
    transportStatusOptions,
    mutators
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
