import React, { useCallback } from 'react'

import { I18n } from '@front/volcanion'
import { UserUtils } from '@front/volcanion/utils'
import { DateTimeField, MenuSelectorField, ModelAutocompleteField, ModelMenuSelectorField, TextField } from '@front/squirtle'
import CPUtils from '@front/squirtle/utils/CPUtils'

import { Grid } from '@mui/material'

const AdaptativeRow1 = ({ mutators }) =>
  <>
    <Grid item xs={2}>
      <TextField name={'order_client_id'} label={I18n.t('order.id.alter')} />
    </Grid>
    <Grid item xs={2}>
      <ModelAutocompleteField
        name={'do.user_id'}
        label={I18n.t('user.search_multiple')}
        model_name={'user'}
        searchMode='search'
        searchQuery={'='}
        autocompleteMutator={mutators.do}
        labelKeys={['label']}
        loadOnFocus={false}
        config={{
          forced_filter: { customerinfo: { '!': null } },
          populate: ['info', 'customerinfo'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.customerinfo?.client_number }),
            ...user
          })
        }}
      />
    </Grid>
    <Grid item xs={2}>
      <TextField name={'do.auth.gsm'} label={I18n.t('phone.label', { count: 1 })} />
    </Grid>
    <Grid item xs={2}>
      <TextField name={'loads.src_contact'} label={I18n.t('transport.passenger')} />
    </Grid>
    <Grid item xs={2}>
      <TextField name='customer_ref' label={I18n.t('bank.b2c.customer_ref')} />
    </Grid>
    <Grid item xs={2}>
      <TextField name='order_doc' label={I18n.t('document.b2c.id')} />
    </Grid>
  </>

const AdaptativeRow2 = ({ mode, company, contract, mutators }) =>
  <>
    <Grid item xs={2}>
      <ModelAutocompleteField
        name={'company'}
        label={I18n.t('company.label', { count: 1 })}
        model_name={'company'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={mutators.b2b}
        labelKeys={['label']}
        config={{
          mutator: elem => ({ label: `${_.get(elem, 'client_id')} - ${_.get(elem, 'name')}`, ...elem })
        }}
        loadOnFocus={false}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs={2}>
      <ModelAutocompleteField
        name={'contract'}
        label={I18n.t('contract.name_client_id')}
        model_name={'companycontract'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={mutators.b2b}
        labelKeys={['label']}
        config={{
          forced_filter: { company },
          validate_filter: useCallback(() => !!company, [company]),
          watchers: [company],
          mutator: companycontract => ({ label: `${_.get(companycontract, 'client_id')} - ${_.get(companycontract, 'name')}`, ...companycontract })
        }}
        loadOnFocus={false}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs={2}>
      <ModelAutocompleteField
        name={'service'}
        label={I18n.t('companyservice.code')}
        model_name={'companyservice'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={mutators.b2b}
        labelKeys={['label']}
        config={{
          forced_filter: { contract },
          validate_filter: useCallback(() => !!contract, [contract]),
          watchers: [contract],
          mutator: companyservice => ({ label: `${_.get(companyservice, 'client_id')} - ${_.get(companyservice, 'name')}`, ...companyservice })
        }}
        loadOnFocus={false}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs={2}>
      <ModelAutocompleteField
        name={'assigned_transport.driver'}
        label={I18n.t('driver.search_multiple')}
        model_name={'user'}
        searchMode='search'
        searchQuery={'='}
        autocompleteMutator={mutators.driver}
        labelKeys={['label']}
        loadOnFocus={false}
        config={{
          forced_filter: { driverinfo: { '!': null } },
          populate: ['info', 'customerinfo'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.info?.user_client_id }),
            ...user
          })
        }}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs={2}>
      <TextField name='assigned_transport.vehicle.info.plaque' label={I18n.t('vehicle.label', { count: 1 })} />
    </Grid>
    {mode === 'order' && (
      <Grid item xs={2}>
        <ModelAutocompleteField
          name='operator'
          label={I18n.t('operator', { count: 1 })}
          model_name='user'
          searchMode='search'
          searchQuery='startsWith'
          autocompleteMutator={mutators.operator}
          labelKeys={['label']}
          config={{
            forced_filter: { staffinfo: { '!': null } },
            mutator: user => ({
              label: UserUtils.getFormatedName(user?.info, { prefix: user?.auth?.username }),
              ...user
            }),
            populate: ['auth', 'info']
          }}
          loadOnFocus={false}
          debounceTime={400}
        />
      </Grid>
    )}
  </>

const AdaptativeRow3 = ({ orderStatusOptions, transportStatusOptions, billingStatusOptions }) =>
  <>
    <Grid item xs={2}>
      <ModelMenuSelectorField
        name={'mode'}
        label={I18n.t('ride.type')}
        model_name={'ridemode'}
        searchMode={'search'}
        labelKeys={['name_translated']}
        config={{
          initial_filter: {},
          sort: ['display_index ASC']
        }}
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs={2}>
      <ModelMenuSelectorField
        name='commercial_formula'
        label={I18n.t('commercial_formula.label', { count: 1 })}
        model_name='commercialformula'
        labelKeys={['name']}
        searchMode={'search'}
        config={{
          initial_filter: {}
        }}
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs={2}>
      <ModelMenuSelectorField
        name='commercial_package.commercial_package_id'
        label={I18n.t('commercialpackage.label', { count: 2 })}
        model_name='commercialpackage'
        labelKeys={['label']}
        searchMode={'search'}
        config={{
          initial_filter: {},
          mutator: cp => ({ ...CPUtils.getTranslatedLabel(cp), ...cp })
        }}
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs={2}>
      <MenuSelectorField
        name='status'
        label={I18n.t('order.status.label', { count: 1 })}
        options={orderStatusOptions}
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs={2}>
      <MenuSelectorField
        name='assigned_transport.status'
        label={I18n.t('transportStatus.label', { count: -1 })}
        options={transportStatusOptions}
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs={2}>
      <MenuSelectorField
        name='billing_status'
        label={I18n.t('billing.status.label', { count: 1 })}
        options={billingStatusOptions}
        multiple
        clearOnEmpty
      />
    </Grid>
  </>

const AdaptativeRow4 = ({ mode }) =>
  <>
    {mode === 'order' && (
      <Grid item xs={4}>
        <TextField name={'source.name'} label={I18n.t('address.source')} />
      </Grid>
    )}
    {mode === 'order' && (
      <Grid item xs={2}>
        <MenuSelectorField
          name={'schedule_type'}
          label={I18n.t('order.schedule_type')}
          options={[
            { label: I18n.t('order.planned.label', { count: 1 }), value: "planned" },
            { label: I18n.t('order.immediate.label', { count: 1 }), value: "immediate" }
          ]}
          multiple
          clearOnEmpty
        />
      </Grid>
    )}
    {mode === 'order' && (
      <Grid item xs={2}>
        <ModelMenuSelectorField
          name={'reason'}
          label={I18n.t('order.reason')}
          model_name={'reason'}
          labelKeys={['name_translated']}
          loadOnMount
          config={{ sort: ['display_index DESC'] }}
          searchMode={'search'}
          allowNone={false}
          multiple
        />
      </Grid>
    )}
    <Grid item xs={2}>
      <ModelMenuSelectorField
        name={'payment_type'}
        label={I18n.t('bank.payment_mode')}
        model_name={'paymenttype'}
        searchMode={'search'}
        config={{
          initial_filter: {},
          sort: ['display_index ASC']
        }}
        labelKeys={['name_translated']}
        multiple
        clearOnEmpty
      />
    </Grid>
    {mode === 'order' && (
      <Grid item xs={2}>
        <ModelMenuSelectorField
          name='channel'
          label={I18n.t('channel.label', { count: 1 })}
          model_name='domain'
          labelKeys={['name']}
          searchMode={'search'}
          config={{
            initial_filter: {},
            forced_filter: { user_type: { '!': 'driver' } }
          }}
          multiple
          clearOnEmpty
        />
      </Grid>
    )}
  </>

const AdaptativeRow5 = () =>
  <>
    <Grid item xs={4}>
      <DateTimeField
        name='requestedAt.start'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY'
        }}
        timeProps={{
          label: I18n.t('prefix.at'),
          minTime: null
        }}
        default_time='00:00'
      />
    </Grid>
    <Grid item xs={4}>
      <DateTimeField
        name='requestedAt.end'
        dateProps={{
          label: I18n.t('prefix.to'),
          inputFormat: 'DD/MM/YYYY'
        }}
        timeProps={{
          label: I18n.t('prefix.at'),
          minTime: null
        }}
        default_time='23:59'
      />
    </Grid>
  </>

const OrderForm = (props) => (
  <Grid container rowSpacing={5}>
    <Grid container item spacing={1}>
      <Grid container item xs={12} spacing={1}>
        <AdaptativeRow1 {...props} />
        <AdaptativeRow2 {...props} />
        <AdaptativeRow3 {...props} />
        <AdaptativeRow4 {...props} />
        <AdaptativeRow5 {...props} />
      </Grid>
    </Grid>
  </Grid>
)

export default React.memo(OrderForm)
