import { FormatUtils } from '@front/volcanion/utils'

class OrderCallbacks {
  static formToFilterHandler(codeIntlPhone) {
    return function formToFilter(values) {
      const { requestedAt, company, contract, service, commercial_formula, ...rest } = values
      return _.merge({},
        rest,
        !!requestedAt && { requestedAt: FormatUtils.intervalFilter(requestedAt?.start, requestedAt?.end) },
        !!company && { service: { contract: { company } } },
        !!contract && { service: { contract } },
        !!service && { service },
        !!commercial_formula && {
          or: [
            { do: { commercial_formula }, service: null },
            { service: { contract: { formula: commercial_formula } } }
          ]
        },
        !!rest?.do?.auth?.gsm && { do: { auth: { gsm: FormatUtils.parsePhoneNumber(rest?.do?.auth?.gsm, `+${codeIntlPhone}`) } } },
        !!rest?.source?.name && { source: { name: { contains: rest?.source?.name } } },
        !!rest?.loads?.src_contact && { loads: { src_contact: { contains: rest?.loads?.src_contact } } },
        !!rest?.order_client_id && { order_client_id: { contains: rest?.order_client_id } }
      )
    }
  }
}

export default OrderCallbacks
